import React from 'react';
import {Link} from 'gatsby';
import {useStaticQuery, graphql} from 'gatsby';
import {BgImage} from 'gbimage-bridge';

import Layout from '../components/layout';
import Seo from '../components/seo';

const NotFoundPage = () => {

    const data = useStaticQuery(graphql `
    query {
        notFoundImage: file(relativePath: {eq: "404-page.jpg"}) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }           
  `);

    const bgImg = data.notFoundImage.childImageSharp.gatsbyImageData;

    return (
        <Layout>
            <Seo title="404: Not found"/>
            <BgImage
                image={bgImg}
                className="flex flex-col mx-auto h-screen justify-center items-center pt-32 px-12"
                style={{
                minWidth: "100%",
                minHeight: "100%"
            }}>
                <h1 className="text-white text-center halogen uppercase text-3xl lg:text-4xl">Page Not Found</h1>
                <Link to="/" title="Home">
                    <button type="button" className="btn-md mt-5">Home</button>
                </Link>
            </BgImage>
        </Layout>
    );
};

export default NotFoundPage;
